.about-wrap {
  padding:55px 0;
  @media(max-width:900px) { padding:40px 0; }
  @media(max-width:600px) { padding:30px 0; }
  @media(max-width:450px) { padding:20px 0; }
  h1 {
    font-size: 30px;
    color: #40464B;
    letter-spacing: 0;
    line-height: 1.3;
    font-weight: 700;
    @media(max-width:900px) { font-size: 26px; }
    @media(max-width:600px) { font-size: 22px; }
    @media(max-width:450px) { font-size: 18px; }
  }
  .main-txt-area {
    .row {
      margin:0;
      display: flex;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        font-size: 16px;
        color: #7C8289;
        letter-spacing: 0;
        line-height:1.5;
        width:50%;
        @media(max-width:900px) {
          width:100%;
        }
        @media(max-width:600px) { font-size:15px; }
        @media(max-width:450px) { font-size:14px; }
        p {
          margin-bottom: 1.5em;
          &:nth-child(1) {
            font-weight:600;
          }
        }

        &:nth-child(1) {

        }
        &:nth-child(2) {
          padding-left:60px;
          @media(max-width:900px) {
            //padding-top:60px;
            padding-left:0;
          }
        }
        ul.gallery-imgs {
          li {
            display:inline-block;
            width:calc(50% - 10px);
            background-repeat: no-repeat;
            background-size:cover;
            background-position: center;
            &:nth-child(1) {
              width:100%;
              margin-bottom:20px;
            }
            &:nth-child(2) {
              margin-right:10px;
            }
            &:nth-child(3) {
              margin-left:10px;
            }
            img {
              opacity:0;
              width:100%;
            }
            @media(max-width:450px) {
              width:100%;
              &:nth-child(2) {
                margin-right:0;
                margin-bottom:20px;
              }
              &:nth-child(3) {
                margin-left:0;
                margin-bottom:20px;
              }
            }
          }
        }
      }
    }
  }
  .hr {
    display: block;
    width:100%;
    height:1px;
    background-color:#E0E0E0;
    margin:75px 0;
    @media(max-width:900px) { margin:55px 0; }
    @media(max-width:600px) { margin:40px 0; }
    @media(max-width:450px) { margin:25px 0; }
  }


  h3.overview-title {
    font-size: 30px;
    color: #40464B;
    letter-spacing: 0;
    line-height: 1.3;
    margin:0;
    @media(max-width:900px) { font-size: 26px; }
    @media(max-width:600px) { font-size: 22px; }
    @media(max-width:450px) { font-size: 18px; }
  }
}

ul.lists {
  display: flex;
  flex-wrap: wrap;
  margin-top:50px;
  @media(max-width:900px) { margin-top:40px; }
  @media(max-width:600px) { margin-top:30px; }
  @media(max-width:450px) { margin-top:20px; }
  li {
    border: 1px solid #E1E1E1;
    background: #EEF1F4;
    margin-bottom:35px;
    @media(min-width:900px) {
      width:33.3333333%;
      &:nth-child(3n+2) {
        border-left:0;
        border-right:0;
      }
    }
    @media(min-width:450px) and (max-width:900px) {
      width:50%;
      &:nth-child(even) {
        border-left:0;
      }
    }
    @media(max-width:450px) {
      width: 100%;
    }
    .img-wrap {
      height:190px;
      background:#fff;
      width:100%;
      display:flex;
      justify-content:center;
      align-items:center;
      img {
        max-width: 137px;
      }
    }
    .txt-wrap {
      border-top: 1px solid #E1E1E1;
      padding:25px 30px;
      font-size: 12px;
      color: #A3A6A9;
      letter-spacing: 0;
      line-height: 1.5;
      h4 {
        font-size: 15px;
        color: #40464B;
        letter-spacing: 0;
        line-height: 1.3;
      }
    }
  }
}
