.checkout-wrap {
  max-width:600px;
  text-align:center;
  margin:0 auto 50px;
  padding-top:25px;
  h1 {
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 1;
    font-style: normal;
    font-weight: 800;
  }
  .checkout-wrap-item {
    text-align:left;
    //background:#EEF1F4;
    margin:40px auto;
    display: block;
    border:1px solid #E1E1E1;
    h2 {
      font-size: 16px;
      color: #FFFFFF;
      letter-spacing: 0;
      line-height: 1;
      background:#000000;
      padding:15px 25px;
      font-style: normal;
      font-weight: 700;
      margin:0;
    }
    .inner {
      padding:15px 25px 25px;
      .btn-wrap {
        margin:20px auto;
        text-align:center;
        a.btn { 
          margin:0 5px;
          @media(max-width:600px) {
            border:0;
            margin:3.5px 0;
            display: block;  
          }

        }
      }
      input[type="checkbox"] {
        display:inline-block;
        width:auto;
        margin:0;
        vertical-align: top;
      }
      label.chk-lbl {
        margin:0;
        display:inline-block;
        padding-left:10px;
        font-style: normal;
        font-weight: 400;
        font-size:14px;
        vertical-align: top;
        line-height: 1.2;
        @media(max-width:600px) {
          width:calc(100% - 15px);
        }
      }
      #del-address-frm {
        display:none;
        padding-top:12px;
      }
      .payment-txt {
        margin-bottom:20px;
      }
    }
  }
  .checkout-action {
    input {
      background:#69AE00;
      width:auto;
      font-size:16px;
      padding:15px 25px;
      font-style: normal;
      font-weight: 800;
      line-height: 1;
    }
  }
}


.checkout-completion {
  text-align:center;
  margin:0 auto 50px;
  padding-top:25px;
  //@media(max-width:600px) { padding-top:0; }
  h1 {
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 1;
    font-style: normal;
    font-weight: 800;
    @media(max-width:900px) { font-size:36px; }
    @media(max-width:600px) { font-size:32px; }
    @media(max-width:450px) { font-size:28px; }
  }
  h3 {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 1;
    background:#013040;
    padding:15px 25px;
    font-style: normal;
    font-weight: 700;
    margin:0;
    @media(max-width:900px) { font-size:15px; }
    @media(max-width:600px) { font-size:14px; }
  }

  .row.top {
    display:flex;
    justify-content:space-between;
    align-content: space-between;
    margin:40px 0;
    text-align:left;
    &:before,
    &:after {
      display: none;
    }
    @media(max-width:900px) {
      flex-direction: column;
    }
    .col {
      width:calc(50% - 20px);
      @media(max-width:900px) {
        max-width:none;
        width:100%;
      }
      background: #FFFFFF;
      display: block;
      border:1px solid #E1E1E1;
      &:nth-child(2) {
        text-align:right;
        @media(max-width:900px) {
          text-align:left;
          margin-top:35px;
        }
      }
      p {
        padding:15px 25px 25px;
      }
    }
  }
}