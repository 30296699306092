#mobile-nav-items {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 15.625em;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 50;
    text-align:left;
    background:#000;
    #mobile-nav-inner-items {
      min-height:100vh;
    }
    ul {
      margin:0;
      li {
        color:#fff;
        font-weight:700;
        text-transform: uppercase;
        display: block;
        text-align:left;
        border-bottom: 1px solid #333;
        a,span {
          color:#fff;
          line-height: 1.25em;
          display: block;
          padding: 14px 20px;
          letter-spacing: 1px;
          cursor: pointer;
          display:block;
          &:hover {
            text-decoration: underline;
          }
          &.active {

          }
      }
      span {
        position: relative;
        &:after {
          font-family: FontAwesome;
          content: "\f107";
          color:#6C6C6C;
          display: inline-block;
          position: absolute;
          top:12.5px;
          font-size:18px;
          right:15px;
        }
      }
    }
    &.second {
      display: none;
      background:#444;
      margin:0;
      li {
        border-bottom:0;
        display:flex;
        span {
          display:inline-block;
          padding:7px 5px 7px 20px;
          &:after {
            display:none;
            content: "";
          }
        }
        a {
          flex:1;
          display:inline-block;
          text-transform: capitalize;
          font-weight:500;
          padding:7px 20px 7px 5px;
        }
      }
    }
    &.open {
      display: block;
    }
  }

    a.mobile-basket {
      color:#fff;
      display: block;
      padding:10px;
      background:#444;
      .cart-counter {
        display:inline-block;
        margin-right:10px;
        position: relative;
        i {
          font-size:24px;
          display:inline-block;
        }
        .cart-counter-mobile-num {
          position: absolute;
          top: -7px;
          right: -10px;
          font-size: 10px;
          color: #FFFFFF;
          line-height: 18px;
          font-weight: 700;
          width: 24px;
          height: 24px;
          background: #CD1A1E;
          border: 3px solid #444;
          display: inline-block;
          border-radius: 50%;
          text-align:center;

        }
      }
      .cart-price {
        text-align: center;
        color: #fff;
        font-size: 16px;
        line-height: 1.35;
        padding-top: 5px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    div {
      display:flex;
      flex-direction: column;
      min-height:100vh;
      align-content: space-between;
      ul {
        flex:1;
      }
    }
}
