html {
  box-sizing: border-box;
}

body {
  background-color:#fff;
  font:16px/1.5em $standard-font-family;
  font-weight:400;
  font-style:normal;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  background:#fff;
  transition: right 0.3s ease;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right: 15.625em;
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.content-wrap {
  p { margin:0 0 1.5em; }
}

.breadcrumbs {
  display:flex;
  font-weight:700;
  margin-bottom:25px;
  a {
    color: #40464B;
    vertical-align: top;
    &.breadcrumb-title {
      font-size:30px;
      line-height: 1;
    }
  }
  i {
    color:#CDCDCD;
    margin:0;
    padding:0 8px 0 10px;
    display: inline-block;
    font-size:24px;
    vertical-align: top;
    line-height: 1;
  }
  img {
    height: 24px;
    margin-top:2px;
  }
  h1{
    color: #40464B !important;
    font-size: 30px !important;
    line-height: 1 !important;
    margin: 0 !important;
  }

  @media(max-width:900px) { font-size:13px;  }
  @media(max-width:600px) { font-size:12px; }
  @media(max-width:450px) { font-size:11px; }
}
.catdesc {
  border-top: 2px solid #eee;
  padding: 30px 0;
  margin-top: 20px;
  h3{
    font-size: 18px;
  }
  p{
    font-size: 14px;
  }
}
.mobile-submenu {
  display: none;
  @media(max-width:825px) {
    display: block;
  }
  .select_style {
    padding:7.5px;
    margin-bottom:15px;
  }
}

.subpage-wrap {
  padding:95px 0;
  font-weight:400;
  font-size: 16px;
  color: #7C8289;
  letter-spacing: 0;
  line-height: 1.5;
  @media(max-width:900px) { padding:85px 0; }
  @media(max-width:600px) { padding:65px 0; }
  @media(max-width:450px) { padding:50px 0; }
  h1 {
    font-size: 50px;
    color: #40464B;
    letter-spacing: 0;
    line-height: 1;
    text-transform: capitalize;
    @media(max-width:900px) { font-size: 44px; }
    @media(max-width:600px) { font-size: 40px; }
    @media(max-width:450px) { font-size: 36px; }
  }
  .main-txt-area {
    p {
      margin:0 0 1.5em;
    }
  }
}



.subpage-wrap-inner {
  display:flex;
  margin-bottom:115px;
  @media(max-width:825px) {
    flex-direction: column-reverse;
    margin-bottom:0;
  }
  .submenu {
    width:340px;
    margin-right:70px;
    font-size:13px;
    @media(max-width:1100px) { width:320px; margin-right:50px; }
    @media(max-width:900px) { width:290px; margin-right:25px; }
    @media(max-width:825px) {
      width:100%;
      margin-top:25px;
    }
    h3 {
      background:$darkblue;
      padding:17px 20px;
      line-height: 1;
      color:#fff;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      border:1px $darkblue solid;
      font-size:14px;
    }
    ul {
      li {
        display:block;
        border:1px #D8D8D8 solid;
        border-top:0;
        position: relative;
        a {
          display:block;
          background:#fff;
          padding:17px 20px;
          line-height: 1;
          text-transform: uppercase;
          color:#4D4D4D;
          letter-spacing: 1.8px;
          font-weight:700;
          position: relative;
          &.selected {
            color:$darkblue;
            text-decoration: underline;
          }
          &:hover {
            text-decoration: none;
          }
        }
        &:after {
          font-family:FontAwesome;
          content: "\f105";
          position: absolute;
          top:5.5px;
          right:14px;
          font-size:24px;
          color:#A3A3A3;
        }
      }
    }
  }
  .main-txt-area {
    flex:1;
    h1 {
      font-size:30px;
      margin:10px 0 30px;
      @media(max-width:900px) { font-size:26px; }
      @media(max-width:600px) { font-size:22px; }
      @media(max-width:450px) { font-size:20px; }
    }
    .main-txt-area-inner {
      p {
        margin:0 0 1.5em;
        &:nth-child(1) {
          font-weight:700;
        }
      }
      img {
        width:100%;
        margin:0;
      }
    }
  }
}

.mobile-submenu {
  display: none;
  @media(max-width:825px) {
    display: block;
  }
  .select_style {
    padding:7.5px;
    margin-bottom:15px;
  }
}
