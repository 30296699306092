
$title-font-family:	"proxima-nova";
$standard-font-family: "proxima-nova";

$container: 1160;

$yellow: #F7CD14;
$red: #B6051A;
$darkblue: #013040;
$blue: #59AAC1;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
