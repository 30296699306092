.contact-inner  {
  padding:50px 0;
  @media(max-width:900px) { padding:40px 0; }
  @media(max-width:600px) { padding:30px 0; }
  @media(max-width:450px) { padding:20px 0; }
  h1 {
    margin:0 0 45px;
    font-size: 30px;
    color: #40464B;
    letter-spacing: 0;
    line-height: 1.3;
    font-weight:700;
    @media(max-width:900px) { font-size: 26px; margin:0 0 35px; }
    @media(max-width:600px) { font-size: 22px; margin:0 0 25px; }
    @media(max-width:450px) { font-size: 18px; margin:0 0 15px; }
  }
  .row {
    margin:0 0 50px;
    @media(max-width:900px) { margin:0 0 35px; }
    @media(max-width:600px) { margin:0 0 25px; }
    @media(max-width:450px) { margin:0 0 15px; }
    display: flex;
    @media(max-width:900px) {
      flex-direction:column;
    }
    .col {
      width:50%;
      @media(max-width:900px) {
        width: 100%;
      }
      &:nth-child(1) {
        padding-right:40px;
        @media(max-width:900px) {
          padding-right:0;
          margin-bottom:40px;
        }
      }
      form {
        label {
          display: none;
        }
        button {
          background-color:#59AAC1;
        }
      }
      .contact-details {
        background: #EEF1F4;
        border: 1px solid #E1E1E1;
        font-size: 16px;
        @media(max-width:600px) { font-size: 15px;  }
        color: #535353;
        letter-spacing: 0;
        line-height: 1.35;
        padding:30px 45px;
        @media(max-width:900px) { padding:25px;  }
        h2 {
          font-size: 30px;
          color: #40464B;
          letter-spacing: 0;
          line-height: 1.3;
          font-weight:700;
          margin:0;
          @media(max-width:900px) { font-size: 26px; }
          @media(max-width:600px) { font-size: 22px; }
          @media(max-width:450px) { font-size: 18px; }
        }
        ul {
          margin:20px 0;
          list-style: none;
          display: flex;
          flex-direction: column;
          li {
            display: flex;
            margin:0 0 10px;
            @media(max-width:500px) { flex-direction: column; }
            strong {
              width:100px;
              @media(max-width:500px) { width:100%; }

            }
            span {
              a { 
                color:#535353; 
                text-decoration:underline;
              }
            }
          }
        }

      }
    }
  }
}