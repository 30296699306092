.listBlogItems {
  margin-bottom:85px;
  .row {
    margin:0;
    padding:0;
    .inner-wrap {
      max-width:825px;
      display:flex;
      border-bottom:1px solid #DCDCDC;
      border-top:1px solid #FFFFFF;
      padding:50px 0;
      @media(max-width:500px) { 
        flex-direction: column; 
      }
      .img-wrap {
        height:240px;
        width:240px;
        border:1px #DBDBDB solid;
        background:#fff;
        padding:10px;
        margin-right:35px;
        img { }
        @media(max-width:900px) { height:200px; width:200px; margin-right:30px; }
        @media(max-width:600px) { height:175px; width:175px; margin-right:25px; }
        @media(max-width:500px) { 
          margin-right:auto;
          margin-left:auto;
          margin-bottom:25px;
        }
       
      }
      .txt-wrap {
        flex:1;
        @media(max-width:500px) { 
          text-align:center;  
        }
        
        h2 { 
          margin:0 0 8px;
          line-height: 1;
          font-size: 26px;
          letter-spacing: 0;
          @media(max-width:900px) { font-size:22px; }
          @media(max-width:600px) { font-size:19px; }
          @media(max-width:450px) { font-size:18px; }
          a {
            color: #000000;
          }
        }
        h3 { 
          margin:0;
          color:#C91413;
          letter-spacing: 0;
          line-height: 1.3;
          font-size:14px;
          a {
            color:#C91413;
          }
        }
        .summary {
          margin:25px 0;
          color:#7B7B7B;
          font-size:16px;
          line-height: 1.5;
        }
        a.btn {  }
      }
    }
    &:nth-child(1) {
      .inner-wrap { border-top:0; }
    }
  }
}


.blog-header {
  margin: 25px auto;
  max-width:825px;
  h1 {
    font-size: 26px;
    color: #000000;
    letter-spacing: 0;
    margin:0 0 8px;
  }
  h2 {
    margin: 0;
    color: #C91413;
    letter-spacing: 0;
    line-height: 1;
    font-size: 14px;
    a { 
      color: #C91413;

    }
  }
}
.blog-body {
  margin: 25px auto;
  max-width:825px;
  color: #7B7B7B;
  font-size: 16px;
  line-height: 1.5;
  p {
    margin:0 0 1.5em;
    &:nth-child(1) {
      font-weight:700;
    }
  }
}