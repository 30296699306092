.slideshow {
  background-image: linear-gradient(-176deg, #003347 14%, #506676 100%);

  .inner-wrap {
    position: relative;
  }
  .controls-wrap {
    .banner-paging-wrap {
      display:block;
      text-align:center;
      padding:20px 0;
      span {
        display: inline-block;
        width:12px;
        height:12px;
        background:#fff;
        border-radius:50%;
        margin:0 2.5px;
        //color:rgba(#FFFFFF, 0.2);
        text-indent: 999999px;
        overflow: hidden;
        text-align:center;
        line-height: 14px;
        cursor: pointer;
        background:rgba(#FFFFFF, 0.2);
        box-shadow: inset 0 2px 3px 0 rgba(0,0,0,0.13);
        &.cycle-pager-active {
          color:#fff;
          background:#fff;
        }
      }
    }
  }
  .cycle-slideshow {
    position: relative;
    z-index: 100;
  }
  .slide {
    height:449px;
    width:100%;
    position: relative;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    @media(max-width:1160px) {
      background:none;
      height:auto;
      padding: 60px 0;
    }
    .slideinner{
      display:flex;
      height:100%;
      align-items: center;
      justify-content: center;
      @media(max-width:600px) {
      flex-direction: column;
      }
    }
    .img-wrap {
      order: 2;
      width: 50%;
      text-align: right;
      @media(max-width:600px) {
      width: 100%;
      margin-top: 30px
      }
      div {

      }
    }
    .txt-wrap{
      order: 1;
      width: 50%;
      padding-right: 8%;
      @media(max-width:600px) {

        width: 100%;
        padding-right: 0;
      }
      h2{
        color: #fff;
        text-transform: uppercase;
      }
      p{
        color: #fff;
        font-size: 15px;
      }
      .btn {
        margin-top: 30px;
      }
    }
  }
}
