footer {
  .top {
    padding-top:45px;
    padding-bottom:45px;
    background:#F0F3F5;
    h4 { 
      font-size: 16px;
      color:#014E63;
      font-style: normal;
      font-weight: 800;
      margin:35px 0 0;
      &:nth-child(1) {
        margin:0;
      }
    }
    ul {
      margin:15px 0;
      padding:0;
      display: block;
      column-count: 4;
      font-size:13px;
      font-weight:700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size:14px;
      font-style: normal;
      font-weight: 400;
      @media(max-width:1100px) { column-count: 3; }
      @media(max-width:800px) { column-count: 2; }
      @media(max-width:500px) { column-count: 1; }
      li {
        a { 
          color:#014E63;
        }
      }
    }
    .row {
      margin:0;
      display: flex;
      align-content: space-between;
      justify-content: space-between;
      @media(max-width:900px) {
        display: block;
        text-align:center;
      }
      .col { 
        font-size:14px;
        font-weight:700;
        display:flex;
        align-items:center;
        @media(max-width:900px) {
          width:100%;
          text-align:center;
          display:block;
          font-size:13px;
        }
        @media(max-width:600px) {
          font-size:12px;
        }
        &:nth-child(1) {
          flex:1;
          color:#fff;
          @media(max-width:900px) {
            margin-bottom:15px;
          }
          strong {
            &:before { 
              font-family: FontAwesome;
              content: "\f041";
              color:#C91413;
              display: inline-block;
              padding-right:10px;
              padding-left:5px;
              font-size:22px;
              line-height: 1;
              position: relative;
              top:2px;
            }
          }
        }
        &:nth-child(2) {
          a {
            color:#fff;
            display: inline-block;
            margin-left:15px;
            &:nth-child(1) {
              &:before { 
                font-family: FontAwesome;
                content: "\f095";
                color:#C91413;
                padding-right:10px;
                padding-left:5px;
                font-size:22px;
                line-height: 1;
                position: relative;
                top:2px;
              }
              margin-right:10px;
            }
            &:nth-child(3) {
              margin-right:5px;
            }
            i {
              font-size:30px;
              @media(max-width:900px) { font-size:26px; }
              @media(max-width:600px) { font-size:21px; }
              @media(max-width:450px) { font-size:17px; }
            }
          }
        }
      }
    }
  }
  .btm {
    padding:22px 0;
    background:#003347;
    @media(max-width:900px) {
      text-align:center;
    }
    .row {
      display: flex;
      margin-left:0;
      margin-right:0;
      @media(max-width:900px) {
        flex-direction: column;
      }
      .col {
        &:nth-child(1) {
          flex:1;
        }
        &:nth-child(2) {
          text-align: right;
          @media(max-width:900px) {
            text-align:center;
            margin-top:15px;
            margin-bottom:15px;
          }
        }
        strong {
          display: block;
          color:#fff;
          font-size:15px;
          font-style: normal;
          font-weight: 700;
        }

        span {
          font-size:14px;
          display: block;
          color:#fff;
          font-style: normal;
          font-weight: 400;
          a {
            color:#fff;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
