.product-view-overview {

  h1 {
    font-size: 36px;
    color: #40464B;
    letter-spacing: 0;
    line-height:1;
    font-weight:800;
    text-transform: capitalize;
  }
  .row {
    margin:0;
    display: flex;
    @media(max-width:970px) {
      display: block;
    }
    .col {
      &:nth-child(1) { 
        width:calc(100% - 515px);
        @media(max-width:970px) {
          width:100%; 
        }
        
      .gallery-items {
        width:100%;
        vertical-align: top;
        order: 1;
        img {
          max-width:100%;
        }
        .gallery-wrapper {
          background: #FFFFFF;
          box-shadow: 0 0 3px 0 rgba(0,0,0,0.24);
          width:100%;
          display: block;
          text-align:center;
          margin-bottom:15px;
          vertical-align: top;
          .cycle-carousel-wrap {
            vertical-align: top;
          }
          .img-wrap {
            display:none;
            width:100%;
            padding: 7px;
            top:0;
            vertical-align: top;
            .img-wrap-inner {
              height:526px;
              vertical-align: top;
              display:flex;
              justify-content:center;
              align-items:center;
              a {
                display: block;
              }
              @media(max-width:900px) { height:426px; }
              @media(max-width:600px) { height:360px; }
              @media(max-width:450px) { height:300px; }

            }
            
            img {
              max-height:526px;
              max-width: 90%;
              @media(max-width:900px) { max-height:426px; }
              @media(max-width:600px) { max-height:360px; }
              @media(max-width:450px) { max-height:300px; }
            }
            &:nth-child(1) {
              display:block;
            }
          }
          .cycle-prev,
          .cycle-next {
            display:inline-block;
            width:40px;
            background:rgba(#000000, 0.25);
            z-index: 1000;
            position: absolute;
            top:calc(50% - 40px);
            text-align:center;
            border-radius: 50%;
            font-size:25px;
            cursor: pointer;
            i {
              color:white;
              position: relative;
              display: block;
              height:40px;
              line-height: 40px;
            }
            &:hover {
              background:rgba(#000000, 0.5);
            }
            &:active {
              top:calc(50% - 39px);
            }
          }
          .cycle-prev { 
            left:10px; 
            i {
              left:-3px;
            }
          }
          .cycle-next { 
            right:10px; 
            i {
              left:3px;
            }
          }
        }
      }
      }
      &:nth-child(2) {
        width:485px;
        margin-left:30px;
        @media(max-width:970px) {
          width:100%;
          margin-left:0;
          margin-top:30px;
        }
        h1 {
          font-weight:800;
          font-size: 36px;
          color: #40464B;
          letter-spacing: 0;
          line-height:1;
          text-transform: capitalize;
          @media(max-width:900px) { font-size:32px; }
          @media(max-width:600px) { font-size:28px; }
          @media(max-width:450px) { font-size:24px; }
        }
        .content-wrap {
          font-size: 16px;
          color: #92959A;
          letter-spacing: 0;
          line-height: 1.5;
          p {
            &:nth-child(1) {
              font-weight:700;
            }
          }
          ul {
            margin:0 0 1.5em;
            li {
              color:#92959A;
              font-weight:400;
              font-size:15px;
              position: relative;
              margin:0 0 10px;
              padding-left:20px;
              &:before {
                content: '\2022';
                display:inline-block;
                color:#59AAC1;
                font-size: 30px;
                position:absolute;
                top:-13px;
                left:3px;
              }
            }
          }
        }
        .lbl {
          font-size: 16px;
          color: #7C8289;
          letter-spacing: 0;
          line-height: 1;
        }
        .pricing-frm {
          .inner {
            background: #F0F3F5;
            border: 1px solid #E0E0E0;
            border-radius: 3px;
            padding:20px 15px;
            margin-bottom:25px;
          }
          .select_style {
            padding:5px 10px;
          }
          .lbl {
            font-size: 16px;
            color: #4F5458;
            letter-spacing: 0;
            line-height: 1;
            margin: 0 0 5px;
            display: block;
            font-weight: 600;
          }
          .price-wrap {
            margin-bottom: 25px;
            .lbl {
              display: block;
              margin-bottom: 3px;
            }
            .price {
              font-size: 22px;
              color: #40464B;
              letter-spacing: 0;
              line-height:1;
              font-weight:700;
            }
          }
          .btn {
            padding:15px 25px;
            border-radius:3px;
          }
        }
      }
    }
  }
}

// PRICING QTY
.pricing-qty-wrap {
  vertical-align: top;
  display: flex;
  align-items: stretch;
  span {
    display: inline-block;
    height:40px;
    background:#59AAC1;
    text-align:center;
    line-height: 36px;
    width:25px;
    cursor: pointer;
    text-align:center;
    position: relative;
    &:active {
      top:1px;
    }
    i {
      color:#fff;
      font-size: 10px;
    }
  }
  input {
    min-width:40px;
    height:40px;
    text-align:center;
    margin:0;
    width:60px;
  }
}

// STRAPLINE
.product-strapline-wrap {
 padding:65px 0;
 background: #59AAC1;
 text-align:center;
 h3 {
  font-size: 28px;
  color: #FFFFFF;
  letter-spacing: 1.79px;
  font-weight:700;
  text-transform: uppercase;
  margin:0 0 15px;
  line-height: 1.3;
  @media(max-width:900px) { font-size:24px; }
  @media(max-width:600px) { font-size:21px; }
  @media(max-width:450px) { font-size:19px; }
 }
 a.btn {
  background-color:#003347;
  padding:10px 20px;
 }
}


// CART DIALOG
.product-cart-wrap {
  text-align:center;
  width:90%;
  margin:0 auto;
  max-width:674px;
  .inner {
    padding:50px 0;
    max-width:480px;
    margin:0 auto;
    @media(max-width:500px) {
      padding:50px 20px;
    }
  }
  button.fancybox-close-small {
    @media(min-width:900px) {
      height:75px;
      width:79px;
      &:after {
        font-size:39px;
        top:20px;
        right:20px;
        height: 36px;
        width:36px;
        line-height: 36px;
      }
    }
  }
  h2 {
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height:1;
    font-style: normal;
    font-weight: 800;
    margin:20px 0 35px;
    @media(max-width:900px) {  
      font-size:36px;
      margin:15px 0 30px;
    }
    @media(max-width:600px) {  
      font-size:30px;
      margin:10px 0 20px;
    }
    @media(max-width:450px) {  
      font-size:26px;
      margin:5px 0 10px;
    }
  }
  .msg {
    padding:0;
    max-width:420px;
    text-align:center;
    width:100%;
    margin:0 auto;
    h4 {
      font-size: 18px;
      color: #7F7F7F;
      letter-spacing: 0;
      font-style: normal;
      font-weight: 400;
      margin:0;
      text-transform: capitalize;
      @media(max-width:900px) { font-size:17px; }
      @media(max-width:600px) { font-size:16px; }
      @media(max-width:450px) { font-size:15px; }
    }
    strong {
      font-size: 18px;
      color: #7F7F7F;
      letter-spacing: 0;
      font-family: "proxima-nova",sans-serif;
      font-style: normal;
      font-weight: 700;
      display: block;
      text-align:center;
      margin:20px 0;
      @media(max-width:900px) { font-size:17px; }
      @media(max-width:600px) { font-size:16px; }
      @media(max-width:450px) { font-size:15px; }
    }
  }
  .wrap {
    padding:0;
    margin:20px 0 0;
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    @media(max-width:600px) { 
      display: block;
    }
    a.btn { 
      margin-left:0;
      margin-right:0;
      margin-top:5px;
      margin-bottom:5px;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 1.8px;
      font-style: normal;
      font-weight: 700;
      padding:15px 10px;
      width:calc(50% - 5px);
      @media(max-width:600px) { 
        width:calc(100%);
        margin:5px 0;
      }
      &:nth-child(1) {
        background:#3D3D3D;
      }
      &:focus {
        outline: none;
      }
    }
  }
}