.homepage-category-grid {
  padding:90px 0;
  h1 {
    font-size: 32px;
    color: #003347;
    letter-spacing: 0;
    line-height: 1.1;
    font-weight:800;
    @media(max-width:900px) { font-size: 29px; }
    @media(max-width:600px) { font-size: 26px; }
    @media(max-width:450px) { font-size: 22px; }
  }
  ul {
    list-style: none;
    li {
      display: inline-block;
      width:calc(25% - 15px);
      background: #EEF1F4;
      border: 1px solid #E1E1E1;
      padding:10px;
      margin: 0 0 20px 18px;
      vertical-align: top;
      @media(min-width:980px) {
        &:nth-child(4n+1) { margin-left:0; }
      }
      @media(min-width:720px) and (max-width:980px) {
        width:calc(33% - 10px);
        &:nth-child(3n+1) { margin-left:0; }
      }
      @media(min-width:500px) and (max-width:720px) {
        width:calc(50% - 9px);
        &:nth-child(2n+1) { margin-left:0; }
      }
      @media(max-width:500px) {
        width:calc(100%);
        margin-left:0;
      }
      .img-wrap {
        display:inline-block;
        vertical-align: top;
      }
      h3 {
        margin:0;
        padding-left:10px;
        display: inline-block;
        width:calc(100% - 37px);
        line-height: 1.2;
        a {
          font-size: 15px;
          color: #40464B;
          letter-spacing: 0;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
}

.subscribe-frm {
  background:#4BA0BB;
  .row {
    margin:0;
    display: flex;
    padding:80px 0;
    align-items:center;
    @media(max-width:900px) {
      flex-direction: column;
      width:100%;
    }
    .col {
      &:nth-child(1) {
        flex:1;
        padding-right:60px;
        @media(max-width:900px) {
          padding-right:0;
          margin-bottom:60px;
          flex:none;
          width:100%;
        }
      }
      h3 { 
        font-size: 28px;
        color: #FFFFFF;
        letter-spacing: 1.79px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 800;
        margin:0 0 10px;
        @media(max-width:900px) { font-size: 24px; }
        @media(max-width:600px) { font-size: 21px; }
        @media(max-width:450px) { font-size: 19px; }
        span {
          color:#F7CD14;
          border-bottom:3px #F7CD14 solid;
          display: inline-block;
          padding-bottom:3px;
        }
      }
      form {
        label { 
          font-size: 14px;
          color: #ADD3E0;
          font-weight: 800;
          font-style: normal;
          margin:0 0 5px;
          display:block;
        }
        div {
          display: flex;
          background:#fff;
          border-radius:2px;
          overflow:hidden;
          @media(max-width:650px) {
            flex-direction: column;
          }
          input { 
            width:calc(100% - 207px);
            border-radius:0;
            border:0;
            padding:0 20px;
            @media(max-width:650px) {
              width:100%;
              padding:10px;
            }
          }
          button { 
            width:207px;
            font-size: 15px;
            color: #FFFFFF;
            letter-spacing: 0;
            font-weight: 800;
            font-style: normal;
            background:#023040;
            margin:0;
            border-radius:0;
            @media(max-width:650px) {
              width:100%;
            }
          }
        }
      }
    }
  }
}