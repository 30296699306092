header.page-header {
  display: block;
  .top {
    padding:30px 0;
    .row {
      display: flex;
      margin:0;
      .col {
        vertical-align: top;
        &:nth-child(1) {
          flex-grow: 1;
        }
        &:nth-child(2) {
          a {
            display: inline-block;
            text-decoration: none;
          }
        }
      }
      a#logo {
        display: inline-block;
        vertical-align: top;
      }
      .search-frm {
        max-width:440px;
        margin-left:30px;
        margin-top: 9px;
        display:inline-block;
        margin-bottom:15px;
        @media(max-width:954px) {
          max-width:50%;
        }
        @media(max-width:755px) {
          max-width:40%;
        }
        @media(max-width:645px) {
          display:none;
        }
        fieldset {
          background:#fff;
          border:1px #E1E1E1 solid;
          overflow:hidden;
          border-radius:3px;
          input {
            background:none;
            display:inline-block;
            width:calc(100% - 40px);
            padding:0 20px;
            margin:0;
            border:0;
          }
          button {
            width:40px;
            height: 40px;
            line-height: 40px;
            border-radius:0;
            display: inline-block;
            margin:0;
            padding:0;
            background:$blue;
            i {
              color:#fff;
            }
          }
        }
      }
    }
    .cart-counter {
      width:73px;
      position: relative;
      text-align:center;
      color:$darkblue;
      padding-top:5px;
      display: inline-block;
      @media(max-width:960px) {
        margin-right: 40px;
        margin-top: 7px;
      }
      i {
        font-size:34px;
      }
      .cart-counter-num {
        position: absolute;
        top:-7px;
        right:6px;
        font-size: 10px;
        color: #004459;
        line-height: 18px;
        font-weight:700;
        width:24px;
        height:24px;
        background: $yellow;
        border: 3px solid #fff;
        display: inline-block;
        border-radius:50%;
        &[data-total-num="0"] {
          display:none;
        }
      }
    }
    .cart-price {
      display: block;
      text-align:center;
      color:$darkblue;
      font-size: 16px;
      line-height: 1.35;
      padding-top:5px;
      font-weight:700;
      @media(max-width:960px) {
        display: none;
      }
    }
  }
  .mid {
    background:$darkblue;
    @media(max-width:960px) {
      display: none;
    }
    .row {
      display:flex;
      align-content: space-between;
      justify-content: space-between;
      margin:0;
      .col {
        font-size:16px;
        ul {
          display: inline-block;
          border-left:1px solid #2A2A2A;
          li {
            display: inline-block;
            border-right:1px solid rgba( #FFFFFF, 0.12);
            position: relative;
            a#showMenu2.highlight {
              background:#fff;
              color:#013040;
            }
            .parent-sub {
              //display: block;
              display: none;
              position: absolute;
              left:0;
              top:50px;
              z-index: 2000;
              //.inner-wrap {
              //  display:flex;
              //  justify-content: stretch;
              //  align-content: stretch;
              //}
              ul.parent-links {
                list-style: none;
                border:0;
                display:block;
                width:710px;
                padding:30px 0;
                columns: 2;
                column-gap:0;
                column-rule: 1px solid #EBEBEB;
                background: #FFFFFF;
                box-shadow: 0 3px 4px 0 rgba(0,0,0,0.28);
                li {
                  display: block;
                  border:0;
                  font-size:13px;
                  margin:0 40px;
                  letter-spacing: 0;
                  position: relative;
                  &:after {
                    font-family: FontAwesome;
                    content: "\f105";
                    color:#858585;
                    position: absolute;
                    top:0;
                    right:0;
                    font-size:20px;
                  }
                  &:nth-child(1) {
                    margin-top:0;
                  }
                  a {
                    padding:0;
                    text-decoration: none;
                    color:#858585;
                    text-transform: capitalize;
                    font-style: normal;
                    font-weight: 400;
                    letter-spacing: 0;
                    background:none;
                    &:hover {

                    }
                    &.active {
                      font-weight:700;
                    }
                  }
                }
              }
              .sub-nav-items {
                display: inline-block;
                background: #F0F0F0;
                width:276px;
                padding:30px 40px;
                ul.third {
                  display: none;
                  &.show {
                    display: block;
                  }
                  border:0;
                  li {
                    border:0;
                    display:block;
                    a {
                      border:0;
                      padding:0;
                      color:#858585;
                      font-style: normal;
                      font-weight: 600;
                      text-transform: capitalize;
                      letter-spacing: 0;
                      background:none;
                    }
                    &.category-menu-title {
                      margin:0 0 20px;
                      a {
                        font-size: 14px;
                        color: #353535;
                        letter-spacing: 1.8px;
                        font-weight:700;
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
            }
            &:hover {
              a {
                //background:rgba(#353535, 0.5);
                text-decoration: none;
              }
            }
            &.active {
              a {
                //background:#353535;
                  text-decoration: underline;
                a {
                  background:none;
                  text-decoration: none;
                }
              }
            }


          }
        }
        a {
          padding:13px 20px;
          display: inline-block;
          color:#fff;
          &#showMenu2 {
            position: relative;
            padding-right:38px;
            &:after {
              font-family: FontAwesome;
              content: "\f107";
              color:#6C6C6C;
              display: inline-block;
              padding-left:12px;
              position: absolute;
              top:11px;
              font-size:18px;
            }
            &.highlight {
              //background:#FFFFFF;
              //color:#353535;
              &:after {
                color:#6C6C6C;
              }
            }
          }
        }
        &:nth-child(1) {
          flex-grow: 1;
          ul {
            li:nth-child(1) {
              a { padding-left:0; }
            }
          }
        }
        &:nth-child(2) {
          font-size:12px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
          a {
            color:rgba(#fff, 0.63);
            padding-right:0;
          }
        }
      }
    }
  }
  .btm {
    background:$yellow;
    @media(max-width:960px) {

    }
    .row {
      margin:0;
      display: flex;
      align-items:center;
      .col {
        padding:10px 0;
        vertical-align: top;
        text-transform: uppercase;
        h4 {
          font-weight: 800;
          span {
            background: #B6051A;
            display: inline-block;
            font-style: normal;
            color:#fff;
            padding:7.5px 10px;
            line-height: 1em;
          }
        }
        &:nth-child(1) {
          flex-grow:1;
          @media(max-width:853px) {
            display: none;
          }
        }
        &:nth-child(2) {
          font-style: normal;
          font-weight: 400;
          text-transform: capitalize;
          font-size:14px;
          color:#023040;
          @media(max-width:440px) {
            font-size: 10px;
          }
          a {
            font-size:18px;
            color:#023040;
            font-weight:800;
            padding-left:5px;
            display: inline-block;
            @media(max-width:440px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  cursor: pointer;
  display:none;
  height: 38px;
  margin:0;
  text-align:center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  color:$yellow;
  border-radius: 0;
  background:$darkblue;
  i {
    font-size:28px;
    position: relative;
    top:5px;
    &:nth-child(2) {
      display:none;
    }
  }
  @media(max-width:960px) {
    display: inline-block;
    position: fixed;
    right:20px;
    top:38px;
    z-index: 9999;
  }
  &.menu-open {
    right:calc(15.625em + 20px);
    i {
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}

