.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}


.category-grid {
  .row {
    display: flex;
    margin:0;
    @media(max-width:900px) {
      flex-direction: column;
    }
    .col {
      &:nth-child(1) {
        width:440px;
        padding-right:75px;
        @media(max-width:900px) {
          padding-right:0;
          margin-bottom:40px;
          width:100%;
        }
      }
      &:nth-child(2) {
        flex-grow:1;
      }
      ul {
        li {
          background: #EEF1F4;
          border: 1px solid #E1E1E1;
          margin-top:15px;
          padding:25px 35px;
          display: flex;
          align-content: space-between;
          justify-content: space-between;
          @media(max-width:660px) {
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
          }
          &:nth-child(1) {
            margin-top:0;
          }
          .txt-wrap {
            flex-grow:1;
            padding-right:15px;
            max-width:325px;

            @media(max-width:660px) {
              width:100%;
              margin:0 auto ;
              padding-right:0;

              order: 2;
            }
            h3 {
              font-size: 19px;
              color: #40464B;
              letter-spacing: 0;
              line-height: 1;
              font-style: normal;
              font-weight: 700;
              a {
                color: #40464B;
              }
            }
            div {
              font-size: 13px;
              color: #92959A;
              letter-spacing: 0;
              line-height: 1.3;
              font-style: normal;
              font-weight: 400;
              margin:10px 0 15px;
            }
            a.btn {
              font-size:12px;
            }
          }
          img {
            max-height:99px;

            @media(max-width:660px) {
              order: 1;
              max-width: 60%;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}



#page-wrap-inner .listProducts {
  h1 {
    font-size: 30px;
    color: #40464B;
    letter-spacing: 0;
    line-height: 1;
    font-weight:800;
    text-transform: capitalize;
    @media(max-width:900px) { font-size:26px; }
    @media(max-width:600px) { font-size:22px; }
    @media(max-width:450px) { font-size:20px; }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width:25%;
      background:#EEF1F4;
      border-top:1px #E1E1E1 solid;
      border-right:1px #E1E1E1 solid;
      margin-bottom:26px;
      position: relative;
      padding-bottom:25px;
      @media(max-width:960px) { width:33%; }
      @media(max-width:650px) { width:50%; }
      @media(max-width:450px) { width:100%; }
      @media(min-width:960px) {
        &:nth-child(4n+1) {
          border-left:1px #E1E1E1 solid;
        }
      }
      @media(min-width:650px) and (max-width:960px) {
        &:nth-child(3n+1) {
          border-left:1px #E1E1E1 solid;
        }
      }
      @media(min-width:450px) and (max-width:650px) {
        &:nth-child(2n+1) {
          border-left:1px #E1E1E1 solid;
        }
      }
      @media(max-width:450px) {
        border-left:1px #E1E1E1 solid;
      }
      .img-wrap {
        background:#fff;
        height:185px;
        display:flex;
        justify-content:center;
        align-items:center;
        div {
          width:80%;
          margin:0 auto;
          display: block;
          text-align: center;
        }
        img {
          max-height:120px;
        }
      }
      .txt-wrap {
        padding:25px;
        min-height: 85px;
        h2 {
          font-weight:700;
          font-size: 15px;
          color: #40464B;
          letter-spacing: 0;
          line-height: 1;
          margin:0;
          text-transform: capitalize;
          a {
            color: #40464B;
          }
        }
        .summary {
          font-size: 12px;
          color: #A3A6A9;
          letter-spacing: 0;
          line-height: 1.5;
          margin-top:5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width:100%;
        }
      }
      a.btn {
        display: block;
        font-size:12px;
        font-style: normal;
        font-weight:800;
        text-align:center;
        position: absolute;
        bottom:0;
        left:0;
        width:100%;
        &:active {
          position: absolute;
          top:auto;
          bottom:-1px;
        }
      }
    }
  }
}


.overview-wrap {
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width:calc(33.3333% - 2px);
      background:#EEF1F4;
      border-right:1px #E1E1E1 solid;
      border-bottom:1px #E1E1E1 solid;
      border-top:1px #E1E1E1 solid;
      margin-bottom:35px;
      .img-wrap {
        height: 185px;
        background:#fff;
        width:100%;
        text-align:center;
        display:flex;
        justify-content:center;
        align-items:center;
        a {
          display: block;
        }
        img {
          max-height:115px;
          max-width:80%;
        }

      }
      .txt-wrap {
        padding:25px;
        h4 {
          font-weight:700;
          font-size: 15px;
          letter-spacing: 0;
          line-height: 1.25;
          margin:0 0 7.5px;
          a {
            color: #40464B;
          }
        }
        p {
          margin:0;
          font-size: 12px;
          color: #A3A6A9;
          letter-spacing: 0;
          line-height: 1.5;
        }
      }
      &:nth-child(3n+1) {
        border-left:1px #E1E1E1 solid;
      }
    }
  }
}
